"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandymanProfession = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var HandymanProfession;
(function (HandymanProfession) {
    HandymanProfession.Schema = centralized_zod_1.z.enum([
        "electrician",
        "plumber",
        "plumber-spolbil",
        "locksmith",
        "leakage",
    ]);
})(HandymanProfession || (HandymanProfession = {}));
exports.HandymanProfession = HandymanProfession;
